import create from 'zustand';

type UseSearchContextState = {
  inputFocused: string | undefined;
  onInputFocus: (inputId: string) => void;
  onInputBlur: () => void;
};

const useSearchContext = create<UseSearchContextState>((set) => ({
  inputFocused: undefined,
  onInputFocus: (inputId: string) => set({ inputFocused: inputId }),
  onInputBlur: () => set({ inputFocused: undefined }),
}));

export default useSearchContext;
