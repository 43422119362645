type DecodedObject = {
  [key: string]: string;
};

const useBase64EncodedData = (): DecodedObject => {
  if (typeof window === 'undefined') return {};

  const urlParams = new URLSearchParams(window.location.search);
  const pt = urlParams.get('pt');

  if (pt !== null && pt.length !== 0) {
    const decodedValue = atob(pt);
    const decodedObject = decodedValue.split(';').reduce((acc, curr) => {
      if (!curr) return acc;

      const [key, val] = curr.split('=');
      return { ...acc, [key.trim()]: val.trim().replace(/"/g, '') };
    }, {});

    return decodedObject;
  }

  return {};
};

export default useBase64EncodedData;
